@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --color-primary: 255 255 255;
    --radius: 0.5rem;
    /* Text/Grey */
    --color-muted: 118 118 118;
    /* Neutrals/Mellem Grå */
    --color-gray: 117 117 11;
    /* Neutrals/Lys grå */
    --color-gray-weak: 244 244 244;
    /* My3 ::Usage/Grey Abroad,*/
    --color-gray-weaker: 168 168 168;

    --color-neutral: 176 176 176;

    /* ? */
    --color-orange: 241 208 195;
    /* ? */
    --color-orange-weak: 244 234 231;

    /* Splash/Orange */
    --color-accent-primary: 243 115 35;
    /* UI Semantics/Green Slected */
    --color-accent-secondary: 0 188 113;

    /* My3 ::Usage/Yellow Light Message */
    --color-yellow: 255 242 217;
    /* My3 ::Usage/Yellow Light Message */
    --color-yellow-foreground: 255 188 66;

    /* My3 ::Usage/Blue Light Data */
    --color-blue: 205 225 237;
    /* My3 ::Usage/Blue Data */
    --color-blue-foreground: 6 107 166;

    /* My3 ::Usage/Green Light Voice */
    --color-green: 182 198 194;
    /* My3 ::Usage/Green Voice */
    --color-green-foreground: 76 133 119;
    /* Forbuk overblik background */
    --color-green-overview: 219 231 228;

    /* My3 ::Usage/Red Light Extra Cost */
    --color-red: 254 210 211;
    /* My3 ::Usage/Red Extra Cost */
    --color-red-foreground: 255 33 38;
  }
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-feature-settings: 'calt' 0;
}

.st0 {
  clip-path: url(#SVGID_2_);
  fill: #0060e6;
}
